<template>
   <div class="-games-index-page">
          <app-lotto-ticket />
          <!-- <div class="js-replace-heading-sub-title-seo-container text-center">
              <h2 class="-sub-title ">แนะนำ เกมคาสิโนสด คาสิโนออนไลน์เว็บตรง</h2>
          </div> -->
          <div class="position-relative overflow-hidden p-3 pb-md-0  bg-blue bg-blue-inner">
            <div class="container text-center">
              <div class="col-xxl-12 me-auto">
                <h1 class="text-white"><span class="text-blue">{{ AGENT_S_BASE }}</span> หวยออนไลน์</h1>
                <h2 class="text-muted mb-2">สนุกไปกับการเดิมพันหวยทุกประเภท <strong>ตลอดเวลา 24 ชั่วโมง </strong></h2>
                <div class="dot-2"><i></i></div>
                <h3 class="text-white mt-3 mt-lg-4 -description">
                  <span class="text-warning text-uppercase ">Lottery online</span>
                  หรือ <i>หวยออนไลน์</i> {{ AGENT_S_BASE }} เรามาพร้อมกับหวยทุกประเภทให้ร่วมสนุก
                  <i>ทั้งหวยรัฐบาล หวยยี่กี หวยลาว ฮานอย หวยหุ้นทั้งในและต่างประเทศ เป็นต้น</i>
                  การบริการรวดเร็วทันใจ จ่ายบาทละ 900 อัพเดทตลอดเวลา<strong> 24 ชั่วโมง </strong>
                  <!-- แทงหวยกับเราที่นี่พร้อมรับผลตอบแทนที่ดีกับทุกประเภทการเดิมพันพร้อมลุ้นรางวัลใหญ่ได้ทุกวันมีโอกาสต่อยอดกำไรได้หลายเท่าตัวจากการเดิมพัน
                  ทุนน้อยก็สนุกได้พร้อมเริ่มต้นแทงหวยออนไลน์ด้วยทุนขั้นต่ำเพียงแค่ 1 บาทเท่านั้นครบครันทุกการเดิมพันและมีโอกาสในการกอบโกยเงินรางวัลได้สูงเป็นอย่างมาก
                  แทงหวยกับเราสะดวกสบายใช้บริการได้ผ่านทางโทรศัพท์มือถือเพียงแค่ปลายนิ้วสัมผัสเท่านั้น -->
                </h3>
                <div class="row m-0">
                <div class="col-6 col-sm-6 col-md-3">
                  <a href="#หวยรัฐบาล"><img  alt="พนันสด"  :src="require('@/assets/hotgame/lot-01.webp')" class="img-fluid ls-is-cached lazyloaded"></a>
                </div>
                <div class="col-6 col-sm-6 col-md-3">
                  <a href="#ยี่กี 15 นาที"><img alt="พนันสด"  :src="require('@/assets/hotgame/lot-03.webp')" class="img-fluid ls-is-cached lazyloaded"></a>
                </div>
                <div class="col-6 col-sm-6 col-md-3">
                  <a href="#หวยออมสิน"><img alt="พนันสด"  :src="require('@/assets/hotgame/lot-02.webp')" class="img-fluid ls-is-cached lazyloaded"></a>
                </div>
                <div class="col-6 col-sm-6 col-md-3">
                  <a href="#หวยหุ้น"><img alt="พนันสด"  :src="require('@/assets/hotgame/lot-04.webp')" class="img-fluid ls-is-cached lazyloaded"></a>
                </div>
              </div>
              </div>
              <button class="btn btn-pink btn-lg px-lg-5 mt-2">เลือกหวยที่ต้องการเดิมพันได้เลย</button>
              <!-- <router-link to="/lotto" class="btn btn-pink btn-lg px-lg-5 mt-4">เดิมพันหวย</router-link> -->
            </div>
            <div class="bg-people"></div>
          </div>
          <app-lottory id="lotto-thai" v-if="AGENT_S_LOTORY_ENABLE"/>
          <app-lotto-set />
          <app-lotto />
          <template>
            <div class="container-fluid py-4">
              <div class="row m-0 p-0">
                <!-- Section 1: ลอตเตอรี่ -->
                <div class="col-md-6 mb-4 p-1">
                  <div class="card h-100 shadow-sm lottery-card py-2 px-2">
                    <div class="card-header bg-primary text-white py-2 huay-header">
                      <h2 class="h3 mb-2">ลอตเตอรี่</h2>
                      <p class="mb-0">เว็บซื้อหวยออนไลน์อันดับ 1</p>
                    </div>
                    <div class="card-body p-2">
                      <p class="text-muted">
                        LOTTOPYN ให้บริการลอตเตอรี่ที่ได้รับความนิยมสูงสุดในไทย คุณสามารถซื้อลอตเตอรี่ผ่านระบบออนไลน์ได้ง่ายๆ
                        ไม่ต้องเสียเวลาเดินทาง เรามีลอตเตอรี่จากทุกจังหวัด ทุกงวด อัปเดตเร็ว อัตราจ่ายสูง และมั่นใจได้ว่าปลอดภัย 100%
                      </p>
                      <div class="features mt-1">
                        <div class="media mb-1 p-3 bg-light2 rounded">
                          <i class="fas fa-ticket-alt fa-2x text-primary mr-3"></i>
                          <div class="media-body">
                            <h5 class="mt-0">อัตราการจ่ายสูงสุด</h5>
                            <p class="text-muted mb-0">จ่ายสูงถึงบาทละ 900</p>
                          </div>
                        </div>
                        <div class="media mb-1 p-3 bg-light2 rounded">
                          <i class="far fa-calendar fa-2x text-primary mr-3"></i>
                          <div class="media-body">
                            <h5 class="mt-0">อัปเดตผลลอตเตอรี่รวดเร็ว</h5>
                            <p class="text-muted mb-0">รวดเร็ว แม่นยำ</p>
                          </div>
                        </div>
                        <div class="media mb-1 p-3 bg-light2 rounded">
                          <i class="fas fa-lock fa-2x text-primary mr-3"></i>
                          <div class="media-body">
                            <h5 class="mt-0">ระบบปลอดภัย</h5>
                            <p class="text-muted mb-0">มั่นใจได้ 100%</p>
                          </div>
                        </div>
                      </div>
                      <!-- <router-link to="/lotto" class="btn btn-primary btn-lg btn-block mt-4 bt1">
                        <span>ซื้อลอตเตอรี่เลย</span>
                        <i class="fas fa-arrow-right ml-2"></i>
                      </router-link> -->
                    </div>
                  </div>
                </div>

                <!-- Section 2: หวยยีกี -->
                <div class="col-md-6 mb-4 p-1">
                  <div class="card h-100 shadow-sm lottery-card  py-2 px-2">
                    <div class="card-header bg-success text-white py-2 huay-header">
                      <h2 class="h3 mb-2">หวยยีกี</h2>
                      <p class="mb-0">ลุ้นรางวัลทุก 5 นาที ตลอด 24 ชั่วโมง</p>
                    </div>
                    <div class="card-body  p-2">
                      <p class="text-muted">
                        หวยยีกี หรือ หวยปิงปอง เปิดให้แทงตลอดทั้งวัน ออกรางวัลทุก 5 นาที ให้คุณสนุกกับการเสี่ยงโชคได้อย่างต่อเนื่อง
                        LOTTOPYN มีระบบอัตโนมัติ อัปเดตผลไว และให้อัตราจ่ายสูงสุด
                      </p>
                      <div class="features mt-1">
                        <div class="media mb-1 p-3 bg-light2 rounded">
                          <i class="fas fa-clock fa-2x text-success mr-3"></i>
                          <div class="media-body">
                            <h5 class="mt-0">ออกผลทุก 5 นาที</h5>
                            <p class="text-muted mb-0">ลุ้นได้ตลอดทั้งวัน</p>
                          </div>
                        </div>
                        <div class="media mb-1 p-3 bg-light2 rounded">
                          <i class="fas fa-money-bill-wave fa-2x text-success mr-3"></i>
                          <div class="media-body">
                            <h5 class="mt-0">อัตราจ่ายสูงถึงบาทละ 850</h5>
                            <p class="text-muted mb-0">ราคาดีที่สุดในตลาด</p>
                          </div>
                        </div>
                        <div class="media mb-1 p-3 bg-light2 rounded">
                          <i class="fas fa-mobile-alt fa-2x text-success mr-3"></i>
                          <div class="media-body">
                            <h5 class="mt-0">เล่นง่ายผ่านมือถือ</h5>
                            <p class="text-muted mb-0">ทุกระบบ ทุกแพลตฟอร์ม</p>
                          </div>
                        </div>
                      </div>
                      <!-- <router-link to="/lotto" class="btn btn-success btn-lg btn-block mt-4 bt1">
                        <span>แทงหวยยีกีตอนนี้</span>
                        <i class="fas fa-arrow-right ml-2"></i>
                      </router-link> -->
                    </div>
                  </div>
                </div>

                <!-- Section 3: หวยไทย -->
                <div class="col-md-6 mb-4 p-1">
                  <div class="card h-100 shadow-sm lottery-card  py-2 px-2">
                    <div class="card-header bg-danger text-white py-2 huay-header">
                      <h2 class="h3 mb-2">หวยไทย</h2>
                      <p class="mb-0">หวยรัฐบาลไทย จ่ายสูง จ่ายจริง</p>
                    </div>
                    <div class="card-body  p-2">
                      <p class="text-muted">
                        เล่นหวยไทยกับ LOTTOPYN แทงได้ทั้งหวยรัฐบาลไทย หวยออมสิน และหวย ธ.ก.ส. ไม่ต้องรอลุ้นนาน
                        ออกรางวัลทุกวันที่ 1 และ 16 ของทุกเดือน สมัครง่าย จ่ายจริง
                      </p>
                      <div class="features mt-1">
                        <div class="media mb-1 p-3 bg-light2 rounded">
                          <i class="fas fa-calendar-alt fa-2x text-danger mr-3"></i>
                          <div class="media-body">
                            <h5 class="mt-0">ลุ้นรางวัลทุกเดือน</h5>
                            <p class="text-muted mb-0">วันที่ 1 และ 16</p>
                          </div>
                        </div>
                        <div class="media mb-1 p-3 bg-light2 rounded">
                          <i class="fas fa-trophy fa-2x text-danger mr-3"></i>
                          <div class="media-body">
                            <h5 class="mt-0">มีเลขเด็ดให้ติดตาม</h5>
                            <p class="text-muted mb-0">วิเคราะห์โดยผู้เชี่ยวชาญ</p>
                          </div>
                        </div>
                        <div class="media mb-1 p-3 bg-light2 rounded">
                          <i class="fas fa-money-bill-wave fa-2x text-danger mr-3"></i>
                          <div class="media-body">
                            <h5 class="mt-0">จ่ายสูงสุดบาทละ 950</h5>
                            <p class="text-muted mb-0">อัตราจ่ายดีที่สุด</p>
                          </div>
                        </div>
                      </div>
                      <!-- <router-link to="/lotto"  class="btn btn-danger btn-lg btn-block mt-4 bt1">
                        <span>แทงหวยไทยเลย</span>
                        <i class="fas fa-arrow-right ml-2"></i>
                      </router-link> -->
                    </div>
                  </div>
                </div>

                <!-- Section 4: หวยหุ้น -->
                <div class="col-md-6 mb-4 p-1">
                  <div class="card h-100 shadow-sm lottery-card  py-2 px-2">
                    <div class="card-header bg-info text-white py-2 huay-header">
                      <h2 class="h3 mb-2">หวยหุ้น</h2>
                      <p class="mb-0">หวยหุ้นออนไลน์จากทั่วโลก</p>
                    </div>
                    <div class="card-body  p-2">
                      <p class="text-muted">
                        ลุ้นโชคไปกับหวยหุ้นจากตลาดหลักทรัพย์ชั้นนำทั่วโลก เช่น หวยหุ้นไทย หวยหุ้นนิเคอิ หวยหุ้นดาวโจนส์ และอีกมากมาย
                        ระบบอัตโนมัติ ใช้งานง่าย ผลแม่นยำ
                      </p>
                      <div class="features mt-1">
                        <div class="media mb-1 p-3 bg-light2 rounded">
                          <i class="fas fa-globe fa-2x text-info mr-3"></i>
                          <div class="media-body">
                            <h5 class="mt-0">เลือกแทงหวยหุ้นได้หลากหลาย</h5>
                            <p class="text-muted mb-0">ตลาดหุ้นชั้นนำทั่วโลก</p>
                          </div>
                        </div>
                        <div class="media mb-1 p-3 bg-light2 rounded">
                          <i class="fas fa-chart-line fa-2x text-info mr-3"></i>
                          <div class="media-body">
                            <h5 class="mt-0">อัปเดตผลรวดเร็ว</h5>
                            <p class="text-muted mb-0">แม่นยำ 100%</p>
                          </div>
                        </div>
                        <div class="media mb-1 p-3 bg-light2 rounded">
                          <i class="fas fa-percent fa-2x text-info mr-3"></i>
                          <div class="media-body">
                            <h5 class="mt-0">อัตราจ่ายสูง</h5>
                            <p class="text-muted mb-0">มากกว่าเจ้าอื่น</p>
                          </div>
                        </div>
                      </div>
                      <!-- <router-link to="/lotto"  class="btn btn-info btn-lg btn-block mt-4 bt1">
                        <span>แทงหวยหุ้นวันนี้</span>
                        <i class="fas fa-arrow-right ml-2"></i>
                      </router-link > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="x-category-provider js-game-scroll-container js-game-container">
              <div class="x-category-provider js-game-scroll-container js-game-container">
                  <div class="-games-list-container container">
                      <div class="x-hot-games-container pt-2">
                          <div class="-inner-heading-wrapper">
                              <img src="@/assets/main/ic-nav-menu-popular-casino.png" class="-icon" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" width="55" height="55">
                              <span class="-title">คาสิโนออนไลน์ ยอดนิยม</span>
                          </div>
                          <nav class="nav-menu" id="navbarProvider">
                            <ul class="nav nav-pills">
                              <li class="nav-item -game-casino-macro-container" :class="{ 'd-none d-sm-none d-md-block': (index == 3) }" v-for="(item,index) in hot_casinno"  v-bind:key="index">
                                <div class="x-game-list-item-macro js-game-list-toggle -big " data-status="">
                                  <div class="-inner-wrapper">
                                    <picture>
                                      <a href="#" class="-btn -btn-play js-account-approve-aware" @click="reqStartGame(item.id, require('@/assets/images/casino/banner/'+item.games_provider_id+'.png'), item.games_provider_id)" v-if="isMember === true">
                                        <img :src="require('@/assets/images/casino/banner/'+item.games_provider_id+'.png')" class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="364" height="231">
                                      </a>
                                      <a href="#loginModal" v-b-modal.modal-user-login class="-btn -btn-play js-account-approve-aware"  v-else>
                                        <img :src="require('@/assets/images/casino/banner/'+item.games_provider_id+'.png')" class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="364" height="231">
                                      </a>
                                      <div class="hotGame">
                                        <img class="img-responsive-logo" :src="require('@/assets/images/ico-hot.png')">
                                      </div>
                                    </picture>
                                    <div class="-overlay">
                                      <div class="-overlay-inner">
                                        <div class="-wrapper-container">
                                          <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" @click="reqStartGame(item.id, require('@/assets/images/casino/banner/'+item.games_provider_id+'.png'), item.games_provider_id)" v-if="isMember === true">
                                            <i class="fas fa-play"></i>
                                            <span class="-text-btn">เข้าเล่น</span>
                                          </a>
                                          <a href="#loginModal" v-b-modal.modal-user-login class="-btn -btn-play js-account-approve-aware" v-else>
                                            <i class="fas fa-play"></i>
                                            <span class="-text-btn">เข้าเล่น</span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                <div class="-title card-section ">{{ item.games_provider_id }}</div>
                              </div>
                            </li>
                          </ul>
                          </nav>
                          <div class="x-info-content-bottom-container -no-line-top -no-line-bottom lazyloaded bg-radius p-0 bg-white mt-1">
                            <div class="container">
                                <div class="-sub-description-inner-wrapper p-3">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-12 -content-container ">
                                            <div class="-content-wrapper text-center">
                                              <img class=" wp-image-135 aligncenter" :src="require('@/assets/images/5starlongblack.png')" style="width: 30%;max-width:300px" v-if="AGENT_S_BASE=='5STARLOTTO'"/>
                                              <img class=" wp-image-135 aligncenter" :src="require('@/assets/images/'+getLogo2)" style="width: 30%;max-width:300px" v-else/>
                                              <h1 style="text-align: center;"><span class="-head">ยินดีต้อนรับสู่ {{ AGENT_S_BASE }}</span></h1>
                                              <div class="dot-1"><i></i></div>
                                              <h3 class="-description ">
                                                {{ AGENT_S_BASE }} ของเราเปิดให้บริการคาสิโนออนไลน์แบบครบวงจร รวมค่ายชั้นนำทั้ง <span>คาสิโนออนไลน์</span>, <span>สล็อตออนไลน์</span>,  <span>พนันบอล</span>, <span>หวยออนไลน์</span> และอื่นๆอีกมากมาย
                                                ต้องการเข้าเล่นเกมคาสิโนประเภทไหนแค่เข้าเล่น กับเราที่นี่ ก็เลือกได้ตามชอบเลย
                                                เป็นการลงทุนที่เชื่อถือได้แบบ 100% มีสถานะทางการเงินสุดมั่นคง <span>จ่ายจริง</span> ครบถ้วน โดดเด่นด้วย<span class="-description2">ระบบฝาก-ถอนแบบออโต้</span>
                                                ที่จะช่วยให้เงิน-เข้าออกบัญชีของสมาชิกเป็นเรื่องง่ายขึ้นกว่าเดิม พร้อมทั้งเจ้าหน้าที่คอยให้บริการทุกช่วงเวลา หากมีปัญหาการเล่น สามารถสอบถามได้ทันที
                                              </h3>
                                              <router-link  v-if="isMember === false"  to="/register-verify" class="btn btn-pink btn-lg px-lg-5 mt-4">สมัครสมาชิก</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="-hot-slot-container m-0 mt-1" v-for="(item_h,key_h) in hot_games"  v-bind:key="key_h">
                              <div class="-inner-heading-wrapper">
                                  <img src="@/assets/main/ic-nav-menu-popular-casino.png" class="-icon" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" width="55" height="55">
                                  <span class="-title">เกมสล็อต {{ key_h }} แนะนำ</span>
                              </div>
                              <nav class="nav-menu" id="navbarProvider">
                                  <ul class="nav nav-pills">
                                     <li class="nav-item -game-casino-macro-container"  :class="{ 'd-none d-sm-none d-md-block': (index == 3) }"  v-for="(item,index) in item_h" v-bind:key="index">
                                      <div class="x-game-list-item-macro js-game-list-toggle -big " data-status="">
                                        <div class="-inner-wrapper">
                                          <picture>
                                            <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" @click="reqStartGame(item.id, item.games_image, item.games_provider_id)" v-if="isMember">
                                              <img  :src="item.games_image"  class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="364" height="231">
                                            </a>
                                            <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" v-b-modal.modal-user-login v-else>
                                              <img  :src="item.games_image"  class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="364" height="231">
                                            </a>
                                            <div class="hotGame">
                                              <img class="img-responsive-logo" :src="require('@/assets/images/ico-hot.png')">
                                            </div>
                                          </picture>
                                          <div class="-overlay">
                                            <div class="-overlay-inner">
                                              <div class="-wrapper-container">
                                                <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" @click="reqStartGame(item.id, item.games_image, item.games_provider_id)" v-if="isMember">
                                                  <i class="fas fa-play"></i>
                                                  <span class="-text-btn">เข้าเล่น</span>
                                                </a>
                                                <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" v-b-modal.modal-user-login v-else>
                                                  <i class="fas fa-play"></i>
                                                  <span class="-text-btn">เข้าเล่น</span>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      <div class="-title card-section ">{{ item.games_name }}</div>
                                    </div>
                                  </li>
                                  </ul>
                              </nav>
                          </div>
                          <div class="x-info-content-bottom-container -no-line-top -no-line-bottom lazyloaded bg-radius p-0 bg-white mt-1">
                            <div class="container">
                                <div class="-sub-description-inner-wrapper p-3">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-12 -content-container ">
                                            <div class="-content-wrapper m-0">
                                                <h1 style="text-align: center;"><span class="-head">เกมสล็อต {{ AGENT_S_BASE }}</span></h1>
                                                <h2 class="text-sup text-center">เลือกค่ายเกมที่ต้องการทดลองเล่น {{ AGENT_S_BASE }} เกมสล็อตและเกมคาสิโนออนไลน์</h2>
                                                <div class="dot-1"><i></i></div>
                                                <h3 class="-description">
                                                  <span>{{ AGENT_S_BASE }} สล็อตเว็บตรง</span> มีค่ายเกมสล็อตและเกมคาสิโนชั้นนำให้เลือกเล่นมากกว่าใคร
                                                  ตอบโจทย์ของเหล่านักเดิมพันมากที่สุด สามารถเลือกเล่นตามความต้องการได้แบบไร้ขีดจำกัด ไม่ว่าจะเป็น
                                                  <i>เกมคาสิโนสด, แทงบอลออนไลน์, บาคาร่า , และสล็อตออนไลน์</i>เล่นง่าย ได้เงินจริง สมัครสมาชิกฟรี
                                                  <span >ยูสเดียวไม่ต้องโยกเงิน บริการผ่านระบบออโต้ที่ทันสมัย</span>
                                                  ภาพสวยคมชัดระดับ Full HD พร้อมทีมงานผู้เชี่ยวชาญที่คอยให้บริการตลอด 24 ชั่วโมง  <span >บริการ ฝาก - ถอน
                                                  รวดเร็วทันใจ</span> เปิดให้บริการทุกวัน 24 ชม. สมัครสมาชิกฟรีได้ที่ลิ้งนี้
                                                  </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="container p-2">
                              <ul class="nav provider-list justify-content-center">
                                <li class="d-flex justify-content-center align-items-center" v-for="(item,index) in ex_game"  v-bind:key="index">
                                  <a  href="javascript:;">
                                    <img class="img-fluid ls-is-cached lazyloaded" :src="require('@/assets/hotgame/'+item.img)">
                                  </a>
                                </li>
                              </ul>
                              <div class="col-lg-12  text-center py-2">
                                <router-link to="/slot" class="btn btn-pink btn-lg px-lg-5 mt-4">เล่นเกมสล็อต</router-link>
                              </div>
                            </div>
                            <div class="position-relative overflow-hidden p-3 pb-md-5  bg-blue bg-blue-inner">
                              <div class="container text-center">
                                <div class="col-xxl-8 me-auto my-5 ">
                                  <h1 class="text-white"><span class="text-blue">{{ AGENT_S_BASE }}</span> พนันสด เกมคาสิโนถ่ายทอดสด</h1>
                                  <h2 class="text-muted mb-2">สนุกไปกับพนันสดกับสาวๆสุดเซ็กซี่ บรรยากาศเสมือนจริงสุดๆ</h2>
                                  <div class="dot-2"><i></i></div>
                                  <h3 class="text-white mt-3 mt-lg-4 -description ">
                                    <span class="text-warning text-uppercase">Live Casino</span>
                                    หรือ <i>เกมพนันสด เกมถ่ายทอดสด</i> {{ AGENT_S_BASE }} เรามาพร้อมกับสาวสวยเซ็กซี่ เป็นผู้ดำเนินการประจำโต๊ะ
                                    บรรยากาศเหมือนเข้าไปเล่นในบ่อนจริงๆ ภาพสวยคมชัดระดับ Full HD พร้อมทีมงานผู้เชี่ยวชาญที่คอยให้บริการตลอด<strong> 24 ชั่วโมง </strong>
                                    มีค่ายเกมชั้นนำให้เลือกเล่น อย่าง <i>Sexy baccarat, SA gaming, Evolution Gaming, Pragmaticplay, Dream gaming, VENUS, WM เป็นต้น </i>
                                    เกมของ {{ AGENT_S_BASE }} ทุกค่ายรองรับมือถือ ทั้งระบบ Androin และ IOS สามารถเลือกเล่นได้ทั้ง บาคาร่า, ไฮโล, รูเร็ต และเกมอื่นๆที่อยู่ในหมวดหมู่ของ
                                    <strong> พนันสด เกมถ่ายทอดสด </strong> จาก {{ AGENT_S_BASE }}</h3>
                                </div>
                                <img alt="พนันสด"  :src="require('@/assets/hotgame/list-livedealer.webp')" class="img-fluid ls-is-cached lazyloaded">
                                <router-link to="/casino" class="btn btn-pink btn-lg px-lg-5 mt-4">เล่นพนันสด</router-link>
                              </div>
                              <div class="bg-people"></div>
                            </div>
                            <div class="position-relative overflow-hidden pt-md-5  bg-white bg-white-inner">
                              <div class="container">
                                <div class="row m-0">
                                  <div class="col-lg-4">
                                    <img alt="เกมคาสิโนสด" :src="require('@/assets/hotgame/J5.webp')" class="img-fluid lazyloaded" >
                                  </div>
                                  <div class="col-lg-8  text-center py-2">
                                    <h1>เดิมพันกีฬา เว็บพนันบอล</h1>
                                    <h2 class="text-sub mb-2">{{ AGENT_S_BASE }} มีบริการแทงบอลออนไลน์ โดยที่ท่านไม่ต้องโยกเงิน <strong> ยูสเดียวแทงได้ทุกลีก</strong></h2>
                                  <div class="dot-1"><i></i></div>
                                  <h3 class="mt-3 mt-lg-4 -description">{{ AGENT_S_BASE }} เปิดเว็บพนันบอล <i>เดิมพันกีฬาโดยตรงได้จาก OBET, AFBSPORT</i>
                                    ครบทุกลีกไม่ว่าจะลีกใหญ่ ลีกเล็ก ซึ่งท่านสามารถเดิมพันบอลสเต็ป2-12คู่ <strong> เรตค่าน้ำดีที่สุดในไทย </strong>
                                    เว็บพนันบอล 2023 เป็นกีฬาระดับโลก ที่ผู้คนต่างให้ความสนใจกันค่อนข้างมาก เดิมพันบอลไปกับทีมที่คุณชอบ
                                    อกจากท่านจะสามารถแทงบอลผ่าน {{ AGENT_S_BASE }} ได้แล้ว ยังมีเกมกีฬาอื่นๆอีกเพียบ<i> เช่น บาสเก็ตบอล, ไก่ชน,
                                      กีฬาเสมือนจริง, คาสิโนเกมส์สด, คีโน, การแข่ง, เทนนิส,เบสบอล, มวยสากล, ฟุตซอล, ฮอกกี้, เกมส์ปาเป้า,
                                      จักรยาน, วอลเล่ย์บอล, รักบี้, เทเบิลเทนนิส, เป็นต้น </i> ที่ครอบคลุมแมตท์ทั้งหมดมากกว่า 3,000 รายการ
                                      เรียกได้ว่าสมัครที่นี้มีครบสามารถเลือกเล่นเพียงท่านเป้นสมาชิกกับเรา</h3>
                                      <router-link to="/sport" class="btn btn-pink btn-lg px-lg-5 mt-4">แทงบอลออนไลน์</router-link>
                                  </div>
                                </div>
                              </div>
                            </div>

                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
</template>

<script>
import lotto from '@/pages/lotteryPage/lotteryPage'
import lottoSetPage from '@/pages/lottoSetPage/lottoSetPage'
import lottoTicketPage from '@/pages/lottoTicketPage/lottoTicketPage'
import lottoryPage from '@/pages/lottoTicketPage/lottoryPage'

import { ApiService } from '@/utils'
import { mapState, mapActions, mapGetters } from 'vuex'
const client = new ApiService({})

export default {
  async mounted () {
    if (process.env.VUE_APP_CASINO_ENABLE.toLowerCase().trim() === 'false') {
      this.$router.push('/member')
    }
    try {
      // await this.getBalanceInfo()
    } catch (error) {
    }
  },
  components: {
    'app-lotto': lotto,
    'app-lotto-set': lottoSetPage,
    'app-lotto-ticket': lottoTicketPage,
    'app-lottory': lottoryPage
  },
  data () {
    return {
      AGENT_S_LOTORY_ENABLE: process.env.VUE_APP_LOTORY_ENABLE,
      PROMOTE_HUAY: process.env.VUE_PROMOTE_HUAY,
      AGENT_EXT_NAME: process.env.VUE_APP_EXT_NAME,
      // TG_MODE
      // hot_casinno: [
      //   { id: 60, games_provider_id: 'PRAGMATICPLAY' },
      //   { id: 648, games_provider_id: 'SA' },
      //   { id: 751, games_provider_id: 'SEXYBCRT' },
      //   { id: 567, games_provider_id: 'DREAMGAMING' }
      // ],
      // ex_game: [
      //   { name: 'redtiger', img: 'redtiger.png' },
      //   { name: 'pragmatic', img: 'pragmatic.png' },
      //   { name: 'pg', img: 'pg.png' },
      //   { name: 'joker', img: 'joker.png' }
      // ],
      // hot_games: {
      //   PGSLOT: [
      //     { id: 2547, games_name: 'Hawaiian Tiki', games_image: 'https://images-store.foxg-gaming.biz/PGSLOT/PG-SLOT-114.png', games_provider_id: 'PGSLOT' },
      //     { id: 2547, games_name: 'Super Golf Drive', games_image: 'https://images-store.foxg-gaming.biz/PGSLOT/PG-SLOT-118.png', games_provider_id: 'PGSLOT' },
      //     { id: 2542, games_name: 'Songkran Splash', games_image: 'https://images-store.foxg-gaming.biz/PGSLOT/PG-SLOT-116.png', games_provider_id: 'PGSLOT' },
      //     { id: 2541, games_name: 'Bakery Bonanza', games_image: 'https://images-store.foxg-gaming.biz/PGSLOT/PG-SLOT-115.png', games_provider_id: 'PGSLOT' }
      //   ],
      //   JOKER: [
      //     { id: 2548, games_name: 'Leprechaun', games_image: 'https://images-store.foxg-gaming.biz/JOKER/zezjtt6ras7ms.png', games_provider_id: 'JOKER' },
      //     { id: 2545, games_name: 'Kraken Hunter', games_image: 'https://images-store.foxg-gaming.biz/JOKER/ooekf9x16xaxn.png', games_provider_id: 'JOKER' },
      //     { id: 1516, games_name: 'Wukong', games_image: 'https://images-store.foxg-gaming.biz/JOKER/texkt79w6ziqs.png', games_provider_id: 'JOKER' },
      //     { id: 1524, games_name: 'Three Kingdoms 2', games_image: 'https://images-store.foxg-gaming.biz/JOKER/yqe1n9d7qj3zy.png', games_provider_id: 'JOKER' }
      //   ],
      //   PRAGMATICPLAY: [
      //     { id: 1782, games_name: 'Wild West Gold Megaways', games_image: 'https://images-store.foxg-gaming.biz/PRAGMATICPLAY/vswayswildwest.png', games_provider_id: 'PRAGMATICPLAY' },
      //     { id: 1112, games_name: 'Cowboys Gold', games_image: 'https://images-store.foxg-gaming.biz/PRAGMATICPLAY/vs10cowgold_tour.png', games_provider_id: 'PRAGMATICPLAY' },
      //     { id: 1265, games_name: 'Wild West Gold', games_image: 'https://images-store.foxg-gaming.biz/PRAGMATICPLAY/vs40wildwest_tour.png', games_provider_id: 'PRAGMATICPLAY' },
      //     { id: 1441, games_name: 'The Ultimate 5', games_image: 'https://images-store.foxg-gaming.biz/PRAGMATICPLAY/vs20ultim5.png', games_provider_id: 'PRAGMATICPLAY' }
      //   ]
      // },
      // PYN_MODE
      hot_casinno: [
        { id: 21, games_provider_id: 'PRAGMATICPLAY' },
        { id: 379, games_provider_id: 'SA' },
        { id: 751, games_provider_id: 'SEXYBCRT' },
        { id: 306, games_provider_id: 'DREAMGAMING' }
      ],
      ex_game: [
        { name: 'redtiger', img: 'redtiger.png' },
        { name: 'pragmatic', img: 'pragmatic.png' },
        { name: 'pg', img: 'pg.png' },
        { name: 'joker', img: 'joker.png' }
      ],
      hot_games: {
        PGSLOT: [
          { id: 3117, games_name: 'Fortune Snake', games_image: 'https://images-store.foxg-gaming.biz/PGSOFTDIRECT/1879752.png', games_provider_id: 'PGSOFTDIRECT' },
          { id: 3091, games_name: 'Oishi Delights', games_image: 'https://images-store.foxg-gaming.biz/PGSOFTDIRECT/1815268.png', games_provider_id: 'PGSOFTDIRECT' },
          { id: 3115, games_name: 'Chocolate Deluxe', games_image: 'https://images-store.foxg-gaming.biz/PGSOFTDIRECT/1666445.png', games_provider_id: 'PGSOFTDIRECT' },
          { id: 3116, games_name: 'Geishas Revenge', games_image: 'https://images-store.foxg-gaming.biz/PGSOFTDIRECT/1702123.png', games_provider_id: 'PGSOFTDIRECT' }
        ],
        JOKER: [
          { id: 2625, games_name: 'Leprechaun', games_image: 'https://images-store.foxg-gaming.biz/JOKER/zezjtt6ras7ms.png', games_provider_id: 'JOKER' },
          { id: 2622, games_name: 'Kraken Hunter', games_image: 'https://images-store.foxg-gaming.biz/JOKER/ooekf9x16xaxn.png', games_provider_id: 'JOKER' },
          { id: 1747, games_name: 'Wukong', games_image: 'https://images-store.foxg-gaming.biz/JOKER/texkt79w6ziqs.png', games_provider_id: 'JOKER' },
          { id: 1790, games_name: 'Three Kingdoms 2', games_image: 'https://images-store.foxg-gaming.biz/JOKER/yqe1n9d7qj3zy.png', games_provider_id: 'JOKER' }
        ],
        PRAGMATICPLAY: [
          { id: 1782, games_name: 'Wild West Gold Megaways', games_image: 'https://images-store.foxg-gaming.biz/PRAGMATICPLAY/vswayswildwest.png', games_provider_id: 'PRAGMATICPLAY' },
          { id: 1112, games_name: 'Cowboys Gold', games_image: 'https://images-store.foxg-gaming.biz/PRAGMATICPLAY/vs10cowgold_tour.png', games_provider_id: 'PRAGMATICPLAY' },
          { id: 1265, games_name: 'Wild West Gold', games_image: 'https://images-store.foxg-gaming.biz/PRAGMATICPLAY/vs40wildwest_tour.png', games_provider_id: 'PRAGMATICPLAY' },
          { id: 1766, games_name: 'The Ultimate 5', games_image: 'https://images-store.foxg-gaming.biz/PRAGMATICPLAY/vs20ultim5.png', games_provider_id: 'PRAGMATICPLAY' }
        ]
      },
      AGENT_S_BASE: process.env.VUE_APP_AGENT_BASE
    }
  },
  computed: {
    ...mapGetters([
      'getGames',
      'getGameUrl',
      'getLogo2'
    ]),
    ...mapState({
      isMember: (state) => state.authModule.isMember
    })
  },
  methods: {
    ...mapActions(['getGamesList', 'getGamesUrl', 'setGameDepositCreditAll', 'getBalanceInfo', 'setLoading']),
    async reqStartGame (gameId, game_img, game_name) {
      this.setLoading(true)
      const resp = await client.post('/game/request/start', JSON.stringify({
        id: gameId
      }))
      this.setLoading(false)
      if (resp.status_code === 'SUCCESS') {
        this.link_to_game = process.env.VUE_APP_CASINO_URL + '/' + localStorage.getItem('client_id') + '/' + resp.data._token
        this.$swal({
          text: 'คุณต้องการเข้าเล่น ' + game_name + ' ?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ใช่',
          cancelButtonText: 'ปิด',
          imageUrl: game_img,
          imageWidth: '100%'
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(process.env.VUE_APP_CASINO_URL + '/' + localStorage.getItem('client_id') + '/' + resp.data._token)
          }
        })
      }
    }
  }
}
</script>

<style>
 .-sub-title {
    margin-top: 2rem;
       margin-bottom: 1rem;
    padding: 0 1rem;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5;
    color: #fff;
    text-align: center;
  }
</style>

<style scoped>
.lottery-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.lottery-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.card-header {
  border-bottom: none;
}

.bg-light2 {
  background-color: rgb(24 24 24 / 40%) !important;
}

.media:hover {
  background-color: rgba(0,0,0,.06)!important;
  transition: background-color 0.3s ease;
}

.btn {
  border-radius: .3rem;
  font-weight: 500;
  padding: .75rem 1.25rem;
}

.btn i {
  transition: transform 0.3s ease;
}

.btn:hover i {
  transform: translateX(5px);
}

/* Custom styling for better user experience */
.card {
  border: none;
  border-radius: 8px;
}

.card-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Enhance media objects */
.media {
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.media:hover {
  transform: translateX(5px);
}

.media i {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(255,255,255,0.9);
}

/* Custom button styles */
.btn-lg {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-lg::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.2);
  transition: all 0.3s ease;
  z-index: -1;
}

.btn-lg:hover::after {
  left: 100%;
}

/* Custom animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.lottery-card {
  background: linear-gradient(180deg, #2d3436 -0.7%, #798f92 100%) !important;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Loading states */
.btn-loading {
  position: relative;
  pointer-events: none;
}

.btn-loading::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1em;
  height: 1em;
  border: 2px solid rgba(255,255,255,0.3);
  border-top-color: #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Accessibility improvements */
.btn:focus,
.media:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0,123,255,0.25);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .card-header {
    padding: 1rem;
  }

  .media {
    padding: 0.75rem;
  }

  .btn-lg {
    padding: 0.5rem 1rem;
  }

  .display-4 {
    font-size: 2.5rem;
  }
}

/* Print styles */
@media print {
  .lottery-card {
    break-inside: avoid;
    page-break-inside: avoid;
  }

  .btn {
    display: none;
  }

  .card {
    box-shadow: none !important;
    border: 1px solid #ddd;
  }
}
.huay-header{
  background: linear-gradient(135deg, #057bb3 -0.7%, #03d6e7 100%) !important;
  border: none;
  border-radius: 20px;
  padding: 12px 24px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 6px 15px rgba(231, 76, 60, 0.4);
  transition: transform 0.3s, box-shadow 0.3s;
  font-weight: bold;
  text-align: center;
}
.bt1{
  background: linear-gradient(135deg, #e74c3c -0.7%, #f1c40f 100%) !important;
  border: none;
  border-radius: 20px;
  padding: 12px 24px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 6px 15px rgba(231, 76, 60, 0.4);
  transition: transform 0.3s, box-shadow 0.3s;
}
.text-muted{
color: white !important;
}
</style>
