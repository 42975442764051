<template>
<div class="container how-css p-2">
  <img src="@/assets/images/how-pyn.png" class="w-100 cursor-pointer" @click="openReg()" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" v-if="AGENT_S_BASE == 'PYN'">
  <img src="@/assets/images/how-nv24.png" class="w-100 cursor-pointer" @click="openReg()" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" v-else-if="AGENT_S_BASE == 'NV24'">
  <img src="@/assets/images/J8.webp" class="w-100 cursor-pointer" @click="openReg()" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" v-else-if="AGENT_S_BASE == 'LOTTO PYN'">
  <img src="@/assets/images/how-v2.webp" class="w-100 cursor-pointer" @click="openReg()" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" v-else>
  <div class="Header w-100">
    <div class="navbar w-100 p-1">
      <div class="container">
        <div class="d-flex flex-row justify-content-between w-100 d-sm-flex">
          <div class="notice-bar flex-fill">
            <div class="txt-notice">
              <marquee direction="left" onmouseover="this.stop()" onmouseout="this.start()">
                <ul id="marquee" class="marquee">
                  <li class="marquee-showing">
                    &nbsp;{{ $t('title') }}
                  </li>
                </ul>
              </marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  methods: {
    openReg () {
      this.$bvModal.show('modal-user-register')
    }
  },
  data () {
    return {
      AGENT_S_BASE: process.env.VUE_APP_AGENT_BASE
    }
  }
}
</script>
<style>
ul.marquee {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 1;
  position: relative;
  width: 100%;
  /* height: 22px; */
  background-color: transparent;
  border: none;
}
ul .marquee li {
  position: absolute;
  top: -999em;
  left: 0;
  display: block;
  white-space: nowrap;
  height: 100%;
  line-height: 1.4;
}
.navbar {
  width: 100%;
  height: auto;
 /*  background: linear-gradient( 132deg, #ce878e 0%, rgb(255 255 255) 40%, rgb(190 189 189) 100%);
  color: white;
  padding: 6px 0px 4px 0px;

      position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem; */
}
/* notice bar */

.notice-bar {
  border-radius: 20px;
  padding: 4px;
  background: white;
  height: 24px;
  width: 100%;
  display: inline-flex;
}
.txt-notice {
  line-height: 20px;
  font-size: 16px;
  color: #556080;
  width: 100%;
  display: inline-flex;
}
</style>
