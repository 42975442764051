<template>
  <div>
    <div id="section-content" class="container container-fluid">
      <div
        class="p-0 w-100 bg-light2 main-content align-self-stretch"
      >
        <div
          class="
            bgwhitealpha
            text-secondary
            rounded
            p-0
            xtarget
            col-lotto
          "
        >
          <h3 class="title-huay p-2 m-0">ลอตเตอรี่</h3>
          <div class="row m-0 p-1">
            <div
              v-for="(item, index) in list" :key="index"
                :class="[
                  'col-12 col-sm-12 col-md-6 col-lg-4 px-1 py-1']"
                  :id="item.titleLottoCatagory"
                  >
              <router-link
                :class="[
                  { 'lotto-close': getIsClose(item.LottoCatagory.lottoCloseTime) }
                  ]"
                :to="`/member/lottery/${item.LottoCatagory.lottoId}`"
                style="padding: 0"
              >
                <div class="d-flex bg-lottery-product">
                  <div class="col-4 p-0 flag-bg-product m-0" :style="{ backgroundImage: `url(${require('@/assets/huay2/h'+item.LottoCatagory.lottoType+'.png')})`, backgroundSize: 'cover', backgroundPosition: 'center' }"></div>
                  <div class="col-8 px-1 px-sm-1 px-md-1 py-1">
                    <div class="d-flex m-0 py-1">
                      <div class="col-12 px-1 ">
                        <div class="huay-card-product pb-md-2 pt-md-1 px-2 py-0 lotto-head" v-bind:class="getBackgroundColor(item.titleLottoCatagory)">
                          <div class="huay-card-name-lotto">
                            <h3 class="m-0 p-2 fix-width-20" v-bind:class="getIconClass(item.titleLottoCatagory)"></h3>
                            <span class="px-2">{{ item.titleLottoCatagory }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-column m-0 pt-2 pb-0">
                      <div class="px-1">
                        <div class="d-flex flex-row">
                          <div class="lotto-time txt-24 time-government lotto-time-x">
                            <i class="far fa-clock pr-1"></i>
                            <span
                            class="countdown"
                            id="offset"
                            data-finaldate="1610871600000"
                            v-html="calTime(item.LottoCatagory.lottoCloseTime)">
                          </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <!-- <div  v-bind:class="list.length == 1 ? 'col-12 p-0' : 'col-6'" v-for="(item, index) in list" :key="index">
              <router-link
                :to="`/member/lottery/lottoset/${item.LottoCatagory.lottoId}`"
                style="padding: 0"
                class="btn btn-outline-primary btn-block btn-contactbox bg-light shadow-sm"
              >
                <span class="lotto-head lotto-yeekee p-0 text-center">{{ item.titleLottoCatagory }}</span>
                <h3 class="m-0 p-2" v-bind:class="getIconClass(item.titleLottoCatagory)"></h3>
              </router-link>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAvailableLottoTicket } from '@/services'
import { mapState, mapActions } from 'vuex'
import {
  getClassIcons,
  getClassBackgroundIcons
} from '@/utils'
import moment from 'moment'

export default {
  async created () {
    try {
      this.setLoading(true)
      const resp = await getAvailableLottoTicket()
      if (resp.status_code === 'SUCCESS') {
        this.list = resp.data.listAvaliableLotto[0].list
        console.log(this.list)
      }
      this.setLoading(false)
    } catch (error) {
      this.setLoading(false)
    }
    setInterval(() => {
      this.getNow()
    }, 1000)
  },
  methods: {
    ...mapActions(['setLoading']),
    ...mapState({
      isMember: (state) => state.authModule.isMember
    }),
    getIconClass (title) {
      if (title) {
        return getClassIcons(title)
      } else {
        return ''
      }
    },
    getBackgroundColor (title) {
      if (title) {
        const classes = getClassBackgroundIcons(title)
        return classes
      } else {
        return ''
      }
    },
    calTime (value) {
      const final = moment(value)
      const now = this.currentDate ? this.currentDate : ''
      const timeleft = final - now
      if (timeleft > 0) {
        const days = Math.floor(timeleft / (1000 * 60 * 60 * 24))
        const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        let seconds = Math.floor((timeleft % (1000 * 60)) / 1000)
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        if (days > 1000) {
          return ''
        } else if (days > 0) {
          return `${days}<small>วัน</small> ${hours}<small>:</small>${minutes}<small>:</small>${seconds}`
        } else {
          return `${hours}<small>:</small>${minutes}<small>:</small>${seconds}`
        }
      } else {
        return '0<small>:</small>00<small>:</small>00'
      }
    },
    getIsClose (value) {
      const final = moment(value)
      const now = this.currentDate ? this.currentDate : ''
      const timeleft = final - now
      if (timeleft > 0) {
        return false
      } else {
        return true
      }
    },
    getTime (time) {
      const timer = moment(time)
      const min = timer.minutes().toString()
      const hour = timer.hours().toString()
      const pad = '00'
      const minCal = pad.substring(0, pad.length - min.length) + min
      const hourCal = pad.substring(0, pad.length - hour.length) + hour

      return `${hourCal}: ${minCal}`
    },
    getNow () {
      this.currentDate = moment()
    },
    getTimeStamp (time) {
      return moment(time)
    },
    isATimeOver (a, b) {
      return moment(a) < moment(b)
    }
  },
  data () {
    return {
      currentDate: '',
      list: []
    }
  }
}
</script>
<style>
.bg-gray{
  background-color: #898989;
}
.fix-width-20{
  width: 20px;
}
.bg-lottery-product {
    position: relative;
    padding: -1px 5px;
    color: white;
    background: transparent linear-gradient(180deg, #DFDFDF 0%, #DFDFDF 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 3px 2px -2px #a39c9c;
}
</style>
