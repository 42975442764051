import { render, staticRenderFns } from "./slot_game.vue?vue&type=template&id=05fe3657"
import script from "./slot_game.vue?vue&type=script&lang=js"
export * from "./slot_game.vue?vue&type=script&lang=js"
import style0 from "./slot_game.vue?vue&type=style&index=0&id=05fe3657&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports