import { render, staticRenderFns } from "./hotgame.vue?vue&type=template&id=4b16e7bf&scoped=true"
import script from "./hotgame.vue?vue&type=script&lang=js"
export * from "./hotgame.vue?vue&type=script&lang=js"
import style0 from "./hotgame.vue?vue&type=style&index=0&id=4b16e7bf&prod&lang=css"
import style1 from "./hotgame.vue?vue&type=style&index=1&id=4b16e7bf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b16e7bf",
  null
  
)

export default component.exports